* {
    outline: none !important;
}

a, a:hover {
    text-decoration: none !important;
    color: inherit
}

body {
    background-color: #eee;
    background-image: url('https://mybrain.com.br/storage/img/background.jpg');
    background-repeat: repeat;
    background-attachment: fixed;    
}

.pointer, .pointer * {
    cursor: pointer;
}

.label-custom {
    color: #2c2c31;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: .75rem;
}

.card {
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(153, 153, 153, 0.2) 0px 2px 4px 0px;
    border-radius: 3px;
    border: none;
}